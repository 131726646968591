.mailingList {
  &__emails {
    background-color: #ffffff;
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }
  .uiKit__input--error {
    padding: 4px 10px 0;
  }
  &__emailAddressesList {
    height: 132px;
    list-style: none;
    margin: 0;
    padding: 10px 10px 0 !important;
    overflow-y: scroll;
    position: relative;
  }
  &__emailAddressesListItem {
    padding: 4px 4px 4px 8px !important;
    border-radius: 4px;
    background-color: #fbede5;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    display: inline-flex;
    justify-content: space-between;

    &.disabled {
      opacity: 0.6;
    }
  }
  &__noEmailAddresses {
    font-size: 13px;
    color: #516f90;
    padding: 4px 0;
  }
  &__emailAddress {
    flex: 1;
    font-size: 13px;
    color: #516f90;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__crossIcon {
    margin-left: 6px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:hover > g {
        stroke: #607180;
      }
    }

    &.disabled svg {
      cursor: not-allowed;
    }
  }
}

.footer {
  button {
    font-size: 14px !important;
  }
}

.save {
  display: flex;
  align-items: center;
  gap: 30px;

  & > div {
    display: flex;
  }
}

.wrapper {
  right: 0;
  bottom: 0;
  left: 205px;
  padding: 16px 24px;
  margin: 20px -24px 0 -24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 500;
  border-top: 1px solid #dddddd;
  gap: 30px;
  width: calc(-205px + 100vw);
  margin: 20px 0 0;
  position: relative;

  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 12px;
    bottom: 0;
    background-color: #fff;
  }
}

.helptext {
  font-size: 14px;
  font-weight: 400;
  color: #0d131a;
}

.actions {
  display: flex;
  align-items: center;
  gap: 30px;
}

.root {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
}

.dark {
  background-color: #f2f3fc;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.selectedTab {
  border-color: #8081ff;
}

.selectedTab.darkTab {
  background-color: white;
  border-bottom: 1px solid #e8e9fc;
}

.darkTab {
  border-left: 1px solid #e8e9fc;
  border-right: 1px solid #e8e9fc;
}
